<template>
  <div class="table_in_work_block">
    <div
      class="preloader_block"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="Загрузка заказов в работе..."
      element-loading-background="rgb(251, 251, 252)"
    >
      <div class="table_block" v-show="inWorkOrders.length > 0">
        <el-table
          :data="inWorkOrders"
          stripe
          style="width: 100%"
          @current-change="handleCurrentChange"
          size="medium"
          :height="'calc(-196px + 100vh)'"
        >
          <el-table-column prop="" label="" width="30">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.oShow"
                class="item"
                effect="dark"
                content="Заказ создан в системе PrintOutlet."
                placement="top-start"
                :open-delay="300"
              >
                <i class="icon-logo"></i>
              </el-tooltip>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                content="Заказ создан менеджером. Управление недоступно."
                placement="top-start"
                :open-delay="300"
              >
                <i class="icon-agent"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="oNumber" label="Номер заказа" width="180">
            <template slot-scope="scope">
              <i class="el-icon-№"></i>
              <span style="margin-left: 10px">{{ scope.row.oNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oDate" label="Дата" width="180">
            <template slot-scope="scope">
              <i class="el-icon-date"></i>
              <span style="margin-left: 10px">{{ scope.row.oDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oName" label="Продукция">
            <template slot-scope="scope">
              <i class="el-icon-tickets"></i>
              <span style="margin-left: 10px">{{ scope.row.oName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oSumm" label="Сумма" width="180">
            <template slot-scope="scope">
              <i class="el-icon-money"></i>
              <span style="margin-left: 10px"
                >{{ Number(scope.row.oSumm) | numeral("0,0.00") }} ₽</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="IsPay" label="Статус оплаты" width="180">
            <template slot-scope="scope">
              <i :class="iconClassStatusPay(scope.row.IsPay)"></i>
              <span style="margin-left: 10px">{{
                scope.row.IsPay | isPayFilter
              }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label="Документы" width="180">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button-->
          <!--                size="mini"-->
          <!--                type="info"-->
          <!--                icon="el-icon-download"-->
          <!--                plain-->
          <!--                @click="downloadInvoice(scope.$index, scope.row)"-->
          <!--                >Скачать-->
          <!--              </el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
            prop="oReadyDate"
            label="Дата готовности"
            width="180"
          >
            <template slot-scope="scope">
              <i class="el-icon-circle-check"></i>
              <span style="margin-left: 10px">{{ scope.row.oReadyDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oStatus" label="Статус" width="180">
            <template slot-scope="scope">
              <i :class="iconClassStatus(scope.row.status)"></i>
              <span style="margin-left: 10px">{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column type="expand" label="Инфо" width="60">
            <template slot-scope="props">
              <div class="expand_block">
                <p>
                  <span style="font-weight: bold; margin-right: 8px"
                    >Менеджер:
                  </span>
                  {{ props.row.oManager }}
                </p>
                <p>
                  <span style="font-weight: bold; margin-right: 8px"
                    >Контактное лицо:
                  </span>
                  {{ props.row.oContact | splitContact }}
                </p>
                <p v-if="props.row.oCustomerCompany">
                  <span style="font-weight: bold; margin-right: 8px"
                    >Юр.лицо:
                  </span>
                  {{ props.row.oCustomerCompany | abbreviationFilter }}
                </p>

                <p v-if="props.row.oBill">
                  <span
                    style="margin-right: 8px; font-weight: bold"
                    v-if="props.row.oBill.info !== ''"
                  >
                    {{ props.row.oBill.info }}:
                  </span>
                  <span
                    style="margin-right: 8px"
                    v-if="props.row.oBill.pdf === true"
                  >
                    <el-button
                      size="mini"
                      type="info"
                      icon="el-icon-download"
                      plain
                      title="Скачать счет"
                      @click="downloadInvoice(props.$index, props.row)"
                      >Скачать
                    </el-button>
                  </span>
                  <span v-if="props.row.oBill.pdf === false">
                    <el-button
                      size="mini"
                      type="info"
                      icon="el-icon-document"
                      plain
                      :loading="generateLoadings"
                      title="Сформировать счет к заказу и скачать"
                      @click="
                        generateAndDownloadInvoice(props.$index, props.row)
                      "
                      >Скачать
                    </el-button>
                  </span>
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-show="inWorkOrders.length === 0 && loading === false"
        class="table_block_empty"
      >
        Нет заказов в работе!
      </div>
    </div>
    <div class="pagination_block" v-show="inWorkOrders.length > 0">
      <el-pagination
        background
        :hide-on-single-page="false"
        :page-count="inWorkOrdersPageCount"
        :current-page="inWorkOrdersCurrentPage"
        @current-change="current_change"
        @size-change="handleSizeChange"
        :page-sizes="[20, 50, 100]"
        :page-size="20"
        layout="total, sizes, prev, pager, next"
        :total="inWorkOrdersTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment";
export default {
  name: "InWorkTable",
  data() {
    return {
      dateSort: [],
      filterStatus: "",
      statusOrders: [
        { id: 1, name: "В работе" },
        { id: 2, name: "Готов" },
      ],
      multipleSelection: [],
      pageSize: 20,
      currentPage: 1,
      pageCount: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: "Последняя неделя",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
          {
            text: "Последний месяц",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
          {
            text: "Последние 3 месяца",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
        ],
      },
      loading: false,
      generateLoadings: false,
    };
  },
  computed: {
    formatDate: function () {
      if (this.dateSort !== null) {
        return this.dateSort.map((item, i) => {
          if (i === 1) {
            return moment(item).add("days", 1).format("YYYY-MM-DDTHH:mm:ss");
          } else {
            return moment(item).format("YYYY-MM-DDTHH:mm:ss");
          }
        });
      } else return null;
    },
    inWorkOrders() {
      return this.$store.getters["Orders/inWorkOrders"];
    },
    inWorkOrdersPageCount() {
      return this.$store.getters["Orders/inWorkOrdersPageCount"];
    },
    inWorkOrdersCurrentPage() {
      return this.$store.getters["Orders/inWorkOrdersCurrentPage"];
    },
    inWorkOrdersTotal() {
      return this.$store.getters["Orders/inWorkOrdersTotal"];
    },
  },
  filters: {
    splitContact: function (value) {
      if (!value) return "";
      return value.split(",", 1)[0];
    },
    isPayFilter: function (IsPay) {
      if (IsPay === true) return "Оплачен";
      if (IsPay === false) return "Не оплачен";
    },
    abbreviationFilter: function (string) {
      let re = /Общество с ограниченной ответственностью/gi;
      return string.replace(re, "ООО");
    },
  },
  methods: {
    downloadInvoice(index, row) {
      this.CreateDownloadLink(row.oBill.link);
    },
    generateAndDownloadInvoice(index, row) {
      this.generateLoadings = true;
      this.$store
        .dispatch("Orders/customerBillGeneratePDF", row.oBill.oid)
        .then((response) => {
          if (
            response.data.status &&
            response.data.status === 2000 &&
            response.data.response.link
          ) {
            this.CreateDownloadLink(response.data.response.link);
          }
        })
        .finally(() => {
          this.generateLoadings = false;
        });
    },
    handleCurrentChange(val) {
      const oId = val.oid;
      if (val.oShow === true) {
        this.$router.push({
          name: "order",
          params: { oId },
        });
      } else {
        this.$notify({
          title: "Заказ создан менеджером",
          message: "Доп.информация и управление заказом не доступны!",
          type: "info",
          offset: 85,
          duration: 6000,
        });
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getInWorkOrders();
    },
    iconClassStatus(oStatus) {
      if (oStatus === "Готов") return "el-icon-circle-check colorGreen";
      if (oStatus === "В работе") return "el-icon-loading colorRed";
      if (oStatus === "Создан") return "el-icon-aim colorGrey";
      else return "";
    },
    iconClassStatusPay(IsPay) {
      if (IsPay === true) return "el-icon-circle-check colorGreen";
      if (IsPay === false) return "el-icon-time colorRed";
      else return "";
    },
    current_change(currentPage) {
      this.currentPage = currentPage;
      this.$store.commit("Orders/inWorkOrdersCurrentPage", currentPage);
      this.getInWorkOrders();
    },
    defaultDate() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      this.dateSort = [start.setHours(0, 0, 0, 0), end.setHours(0, 0, 0, 0)];
    },
    getInWorkOrders() {
      this.loading = true;
      let request = {
        type: "work",
        page: this.currentPage,
        ordersperpage: this.pageSize,
        fromdate: this.formatDate[0],
        todate: this.formatDate[1],
        pay: null,
        company: null,
      };
      this.$store.dispatch("Orders/inWorkOrders", request).finally(() => {});
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".preloader_block",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(251, 251, 252)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
    CreateDownloadLink(linkPdf) {
      let link = document.createElement("a");
      link.setAttribute("href", linkPdf);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "");
      link.click();
    },
  },
  watch: {
    dateSort(value) {
      if (value) {
        this.getInWorkOrders();
      } else if (value === null) {
        this.defaultDate();
        this.getInWorkOrders();
      }
    },
    inWorkOrders() {
      this.loading = false;
    },
    filterStatus() {
      this.getInWorkOrders();
    },
  },
  mounted() {
    this.defaultDate();

    // this.getInWorkOrders();
  },
  destroyed() {
    this.$store.commit("Orders/inWorkOrders", []); //todo подумать как очищать список и надо ли
  },
};
</script>
<style scoped>
.table_in_work_block,
.preloader_block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.table_in_work_block .preloader_block {
  position: relative;
  min-height: calc(-196px + 100vh);
}
.el-table th,
.el-table tr {
  cursor: pointer;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: green;
}
.colorGrey {
  color: gray;
}

.block_date_picker {
  display: flex;
  width: max-content;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}
.note {
  font-size: 12px;
}
.block_date_picker:active {
  border-color: #d2d4da;
}
.block_date_picker .title {
  width: 124px;
  padding: 8px;
  font-size: 14px;
  color: rgb(96, 98, 102);
}
.sort_block {
  display: flex;
  width: max-content;
  height: 60px;
  justify-content: flex-start;
  gap: 24px;
}
.block_date_picker .el-input__inner {
  border: unset !important;
  height: 38px;
  width: 300px;
}
.pagination_block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.table_block_empty {
  text-align: center;
  font-size: 24px;
}

.el-range-editor .el-range-input {
  color: #ea4f00;
}
.el-select .el-input .el-input__inner {
  color: #ea4f00;
  font-weight: 700;
}
.el-input__inner::placeholder {
  font-weight: 400 !important;
}
.el-pagination .el-select .el-input {
  width: 140px;
  margin: 0 5px;
}
.expand_block {
  display: flex;
  gap: 24px;
  align-items: center;
}
</style>
