<template>
  <in-work-table />
</template>

<script>
import InWorkTable from "@/components/InWork/InWorkTable";
export default {
  name: "InWork",
  components: { InWorkTable },
};
</script>

<style scoped></style>
